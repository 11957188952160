@import url('/css/_datepicker.css');
h1 {
    margin: 0;
    padding: 0;
    text-align: center;
  }
  html, body, .app {
    margin: 0;
    padding: 0;
    position: relative;
    width: 100%;
    height: 100vh;
  }
  .popup {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background-color: rgba(0,0,0, 0.5);
    z-index: 100;
  }
  .popup_inner {
    position: absolute;
    left: 25%;
    right: 25%;
    top: 25%;
    bottom: 25%;
    margin: auto;
    background: white;
    animation: popup 0.2s;
  }
  .inner_popup{
    position: absolute;
    left: 25%;
    right: 25%;
    top: 25%;
    bottom: 25%;
    margin: auto;
    background: white;
    animation: popup 0.2s;
  }
    .inner_popup span{
    display: inline-block;
    background-color: transparent;
    padding-left: 10px;
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
  }
  .tdStyle {
    padding-bottom: 10px;
    padding-top:30px;
    width:200px
  }
  .textTransformation{
    padding-bottom: 10px;
    padding-top:30px;
    width:200px;
    text-transform:capitalize;
  } 
  .orderDetail{
    display: flex;
    align-items: center;
  }
  .productDetail{
    margin-left: 10px;
  }
  .details{
    margin-left: 20px;
  }
  .popup_inner span{
    display: inline-block;
    background-color: #FF6347;
    padding-left: 10px;
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
  }
  @keyframes popup {
    0%{
      transform: scale(0)
    }
    10%{
      transform: scale(0.1)
    }
    20%{
      transform: scale(0.2)
    }
    30%{
      transform: scale(0.3)
    }
    40%{
      transform: scale(0.4);
    }
    50%{
      transform: scale(0.5);
    }
    60%{
      transform: scale(0.6);
    }
    70%{
      transform: scale(0.7);
    }
    80%{
      transform: scale(0.8);
    }
    90%{
      transform: scale(0.9);
    }
    100%{
      transform: scale(1);
    }
  }