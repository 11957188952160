@import url(/css/_datepicker.css);
.css-1aya2g8{
    border-width: 0 !important;
    border-bottom-width: 1px !important;
    border-radius: 0 !important;
    background-color: transparent !important;
    
}
.multi-dropdown .css-1uq0kb5{
    display: none;
}
.form-group .css-10nd86i{
    padding: 0;
    padding: 0 10px;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAAFCAYAAAHgVPb9AAAAAXNSR0IArs4c6QAAAF1JREFUCB1jjIpLa2AAgcjY1P9gRkxChgOIwQgXAQsDCWYQA6RBR89ov56BCePli2cPYCoDqQLpxdAPkgABRhABMuf////1IDYyYGRkbFy2aFYDWBFMAqYYJgkTBwBFgyQ6SFvuBQAAAABJRU5ErkJggg==);
    background-repeat: no-repeat;
    background-position: 95% 49%;
}
.css-10nd86i .css-vj8t7z{
    border-width: 0;
    border-bottom-width: 1px;
    border-radius: 0;
    background-color: transparent;
}
.form-group .css-2o5izw{
    border-width: 0;
    border-bottom-width: 1px;
    border-bottom-color: rgb(199, 209, 219);
    border-radius: 0;
    box-shadow: none;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAAFCAYAAAHgVPb9AAAAAXNSR0IArs4c6QAAAF1JREFUCB1jjIpLa2AAgcjY1P9gRkxChgOIwQgXAQsDCWYQA6RBR89ov56BCePli2cPYCoDqQLpxdAPkgABRhABMuf////1IDYyYGRkbFy2aFYDWBFMAqYYJgkTBwBFgyQ6SFvuBQAAAABJRU5ErkJggg==);
    background-repeat: no-repeat;
    background-position: 97% 50%;
}
.form-group .css-2o5izw:hover{
    border-width: 0;
    border-bottom-width: 1px ;
    border-radius: 0;
    border-color:rgb(199, 209, 219);
}
.form-group .css-1rtrksz{
    padding: 0;
}
.form-group .css-d8oujb,.form-group .css-1sjym57,.form-group .css-1ep9fjw{
    display: none;
}
.form-group .css-2o5izw{
    transition: all 0s !important;
}

.header-main .navbar-brand img{
    display: inline-block;
}

.time_cls{
    width: 253px;
    margin: auto;
    margin-left: 91px;
}

.shopifycls{
    width: auto;
    margin-left: 15px;
}
.shopify_yon{
    margin-bottom: 15px;
}
.tim1_cls{
    width: 386px;
    border: 1px solid #ccc;
    /* height: 19px; */
    border-right: none;
    border-left: none;
    border-top: none;
    outline: none !important;
    font-size: 15px;
    color:rgb(138, 141, 144);
}





@media screen and (max-width:767px){
    .main-container{
        margin-top: 40px !important;
    }
    textarea{
        width: 100%;
    }
    .register-heading{
        font-size: 18px !important;
    }
    .register-main .form-group{
        margin-left: 0 !important;
    }
    .form-group .css-10nd86i{
        padding: 0;
        background-position: 98% 49%;
    }
    .register-main{
        width: 100% !important;
        border-width: 0 !important;
    }
    .register-main .form-horizontal .form-group{
        margin-left: 0;
        margin-right: 0;
        margin-top: -9px;
    }
    .header-main .navbar-brand{
            margin-left: 0 !important;
    }
    .navbar-default .navbar-nav li .active{
        border-bottom-width: 0 !important;
    }
    .lbl-mobile{
        width: 0 !important;
    }
    input[type=checkbox].chkbox{
        margin: 2px 0 0;
    }
    .chkbox-container{
        display: flex;
    }
    .verify{
        width: 100% !important;
        text-align: center;
    }
    .submitStyle{
        width: 100% !important;
    }
    .navbar-toggle{
        display: none !important;
    }
    
}


@media screen and (min-device-width: 767px) and (max-device-width:  960px)
{
.timepick-contaioner {
    display: flex;
    margin-left: -139px !important; 
}
}
.shopify_yon{
    margin-left: 24% !important;
    margin-top: 20px;
}
.timepick-contaioner{
    margin-left: 24% !important;
    margin-bottom: 20px;
    margin-top: -20px;
}
.shopifycls{
    margin: auto;

}
.shopify_options{
    display: flex;
}
@media screen and (min-device-width: 1540px) and (max-device-width:  1920px)
{
.timepick-contaioner {
    margin-left: 24% !important;
    margin-bottom: 35px;
    margin-top: -20px;
}
}
@media screen and (min-device-width: 320px) and (max-device-width:  375.98px)
{
    .lab2_cls{
        margin-left: -1px;
    }
    .lbl_cls_tim2{
        margin-right: 22px;
    }
    .labclsreg{
        margin-left: 0% !important;
    }
    .countrycls{
        padding: 0% !important;
    }
    .shopify_yon{
        margin-left: 0% !important;
        margin-bottom: 6% !important;
    }
}


@media screen and (min-device-width: 768px) and (max-device-width:  1024px)
{
.timepick-contaioner {
    margin-left: 24% !important;
}
.timepick-contaioner1{
    margin-left: 14px !important;
}
.tim1_cls {
    width: 260px !important;
    color:rgb(138, 141, 144);
}
.catsubcat{
    margin-left: 18% !important;
    font-size: 15;
    font-weight: 450;
    opacity: 0.5;
}
}


@media screen and (min-device-width: 1025px) and (max-device-width:  1440px)
{
.timepick-contaioner {
    margin-left: 24% !important;
}
.tim1_cls {
    width: 333px;
    color:rgb(138, 141, 144);
}
}
@media screen and (max-width: 425px) and (max-device-width:  370px){
    .labclsreg{
        margin-left: 0% !important;
    }
    .timepick-contaioner {
    margin: auto;
    margin-left: -3px !important;
    margin-bottom: 15px;
    }
    .timepick-contaioner1{
        margin-left: -3px !important;
    }
    .lbl_cls_tim2{
        margin-right: 32px;
    }
    .countrycls{
        padding: 0% !important;
    }
    .shopify_yon{
        margin-left: 0% !important;
        margin-bottom: 6% !important;
    }
}

 
@media (min-width: 375px) and (max-width: 425.98px){
.lbl-mobile {
margin-left: 7px !important;
}
.tim1_cls{
    margin-top: 28px;
}
.timepick-contaioner{
    margin-left: 3px !important;
}
.timepick-contaioner1{
    margin-left: -7px;
    margin-right: 2px;
}
.lab2_cls{
    margin-top: 22px;
}
.catsubcat{
    margin-left: 4% !important;
    font-size: 15;
    font-weight: 450;
    opacity: 0.5;
}
.labclsreg{
    margin-left: 0% !important;
}
.countrycls{
    padding: 0% !important;
}
.shopify_yon{
    margin-left: 0% !important;
    margin-bottom: 6% !important;
}
}
@media (min-width: 1440px) and (max-width: 1999.98px){
    .lbl-mobile{
        width: 24% !important;
    }
    .catsubcat{
        margin-left: 19% !important;
        font-size: 15;
        font-weight: 450;
        opacity: 0.5;
    }
}

@media (min-width: 2000px) and (max-width: 2499.98px){
    .lbl-mobile{
        width: 24% !important;
    }
    .tim1_cls {
width: 468px !important;
    }
    .catsubcat{
        margin-left: 19% !important;
        font-size: 15;
        font-weight: 450;
        opacity: 0.5;
    }
}

@media (min-width: 2500px) and (max-width: 2999.98px){
    .lbl-mobile{
        width: 24% !important;
    }
    .tim1_cls {
        width: 613px !important;
        color:rgb(138, 141, 144);
    }
    .timepick-contaioner{
        margin-left: 24% !important;
    }
    .catsubcat{
        margin-left: 18% !important;
        font-size: 15;
        font-weight: 450;
        opacity: 0.5;
    }
}


.submitStyle{
    background-color: #595D65;
    background-image: none;
    width: 85%;
    margin-bottom: 32px;
    margin-top:15px;
    font-weight: normal;
    outline: none;
    color: #FFFFFF;
}
.btn.submitStyle:hover{
    outline: none;
    color: #FFFFFF;
}
.hover:hover label{
    display: block !important;
    color: #FFFFFF;
}
.slider-control-bottomcenter ul{
    top: 28px !important;
}
.slider-control-bottomcenter ul li button{
    padding: 0 !important;
}
.header-main{
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 2;
}
.loader{
    background-color: rgb(241, 244, 245);
    position: fixed; 
    z-index: 999; 
    height: 100%; width: 100%; overflow: visible; margin: auto; top: 0; left: 0; bottom: 0; right: 0; text-align: center;
}
.new-orders{
    display: flex;
    justify-content: space-between;
}
.content{
    display: flex;
    width: 45%;
    margin-top: 30px;
}
.content img{
    width: 88px;
    height: 88px;
}
.content:nth-child(even){
    align-self: flex-end;
}
.content:nth-child(odd){
    align-self: flex-start;
}
.orders-list{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}
.item-details{
    margin-left: 20px;
    color: #595D65;
}
.item-name{
    margin-bottom: 5px;
}
.availablility{
    margin-top: 5px;
}
.availablility span{
    display: inline-block;
    width: 120px;
    margin-right: 5px;
    border: 1px solid #D8D8D8;
    border-radius: 4px;
    text-align: center;
    font-size: 12px;
    padding: 5px 0 3px 0;
    cursor: pointer;
}
.order-num{
    font-size: 12px;
}
.overview{
    margin-top: 100px;
}
.stats{
    border: 1px solid #979797;
    border-radius: 4px;
    margin-top: 20px;
}
.stats div{
    display: inline-block;
    width: 19.8%;
    border-right:1px solid #979797; 
    padding: 20px 0;
    text-align: center;
    color: #595D65;
}
.stats div:last-child{
    border: 0;
}
.number{
    font-size: 28px;
}
.name{
    font-weight: 500;
}
.view-all a,.view-all a:hover,.view-all a:focus{
    color: #595D65;
    text-decoration: none;
}
.selling-price{
    position: relative;
}
.currency{
    position: absolute;
    top: 28px;
    left: 24%;
}
.selling-price input{
    padding-left: 15px !important;
}
.date-picker{
    margin-left: -15px;
    margin-right: -15px;
}
.date-picker .SingleDatePickerInput__withBorder{
    width: 100%;
    border: none;
    border-bottom: 1px solid #C7D1DB;
}
.date-picker .SingleDatePicker{
    width: 100%;
}
.date-picker .SingleDatePickerInput_calendarIcon{
    margin-left: 0;
    padding-left: 0;
}
.date-picker .DateInput{
    width: 85%;
}
.tim2_cls{
    width: 386px;
    border: 1px solid #ccc;
    /* height: 19px; */
    border-right: none;
    border-left: none;
    border-top: none;
    outline: none !important;
    font-size: 15px;
    color:rgb(138, 141, 144);
    margin-bottom: 20px;
}
.sync_main{
    display: flex;
    flex-direction: row;
}
.sync_sub1{
     display: flex;
     flex-direction: column;
     margin-left: 27% !important;
     font-size: 12px;
     font-weight: 400;
}
.sync_sub2{
    display: flex;
    flex-direction: column;
    margin-left: 8% !important;
    font-size: 12px;
    font-weight: 400;
}
.sync_sub2 #success{
    color: #043505;
    font-weight: 600
}
.sync_sub2 #fail{
    color: red;
    font-weight: 600
}
.sync_sub1 .spa1{
    font-size: 15px;
    color: rgb(138, 141, 144);
    margin: auto;
    margin-top: 7px;
}
.sync_sub2 .spa1{
    font-size: 15px;
    color: rgb(138, 141, 144);
    margin: auto;
    margin-top: 7px;
}

@media(min-width: 1px) and (max-width: 320.98px)
{
.tim_div_input{
    margin-left: -6% !important;
}
.tim2_cls {
    width: 164px !important;
}
.sync_sub1{
    margin-left: 0px !important;
}
.sync_sub2{
    margin-left: 15% !important;
}
}
@media(min-width: 320px) and (max-width: 375.98px){
    .tim2_cls {
        width: 162px !important;
    }
    .tim_div_input{
    margin-left: -5% !important;
    }
    .sync_sub1{
        margin-left: 0px !important;
    }
    .sync_sub2{
        margin-left: 16% !important;
    }
}
@media (min-width: 375px) and (max-width: 425.98px) {
    .tim2_cls {
        width: 197px !important;
    }
    .tim_div_input{
    margin-left: -5% !important;
    }
    .sync_sub1{
        margin-left: 0px !important;
    }
}
@media (min-width: 425px) and (max-width: 576.98px){
    .tim2_cls {
        width: 229px !important;
    }
    .tim_div_input{
    margin-left: -5% !important;
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {
    .tim2_cls {
        width: 323px !important;
    }
    .tim_div_input{
    margin-left: -5% !important;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    .tim2_cls {
        width: 237px !important;
    }
    .tim_div_input{
    margin-left: 24% !important;
    }
    .catsubcat{
        margin-left: 22% !important;
        font-size:  10px;
        font-weight: 400;
        }
}

@media (min-width: 992px) and (max-width: 1199.98px)
{
    .tim2_cls {
        width: 315px !important;
    }
    .tim_div_input{
    margin-left: 24% !important;
    }
    .catsubcat{
        margin-left: 20% !important;
        font-size:  10px;
        font-weight: 400;
        }
        .sync_sub1{
            margin-left: 23% !important;
        }
}
@media (min-width: 1200px) and (max-width: 1349.98px) {
    .tim2_cls {
        width: 408px !important;
    }
    .tim_div_input{
        margin-left: 24% !important;
        }
        .sync_sub1{
            margin-left: 23% !important;
        }
}
@media (min-width: 1428px) and (max-width: 1350px){
.tim_div_input{
    margin-left: 24%;
}
}
@media (min-width: 1440px) and (max-width: 1999.98px){
    .tim2_cls {
        width: 440px !important;
    }
    .tim_div_input{
        margin-left: 24% !important;
        }
        .catsubcat{
            margin-left: 19% !important;
            font-size:  10px;
            font-weight: 400;
            }
            .sync_sub1{
                margin-left: 24% !important;
            }
}
@media (min-width: 1350px) and (max-width: 1439.98px) {
    .tim_div_input{
        margin-left: 24% !important;
        }
        .catsubcat{
            margin-left: 20% !important;
            font-size:  10px;
            font-weight: 400;
            }
}
@media (min-width: 2000px) and (max-width: 2499.98px){
    .tim2_cls {
        width: 611px !important;
    }
    .tim_div_input{
        margin-left: 24% !important;
        }
        .catsubcat{
            margin-left: 20% !important;
            font-size:  10px;
            font-weight: 400;
            }
}

@media (min-width: 2500px) and (max-width: 2999.98px){
    .tim2_cls {
        width: 779px !important;
    }
    .tim_div_input{
        margin-left: 24% !important;
        }
        .catsubcat{
            margin-left: 18% !important;
            font-size:  10px;
            font-weight: 400;
            }
    .sync_sub1{
        margin-left: 31% !important;
    }
}

h1 {
    margin: 0;
    padding: 0;
    text-align: center;
  }
  html, body, .app {
    margin: 0;
    padding: 0;
    position: relative;
    width: 100%;
    height: 100vh;
  }
  .popup {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background-color: rgba(0,0,0, 0.5);
    z-index: 100;
  }
  .popup_inner {
    position: absolute;
    left: 25%;
    right: 25%;
    top: 25%;
    bottom: 25%;
    margin: auto;
    background: white;
    -webkit-animation: popup 0.2s;
            animation: popup 0.2s;
  }
  .inner_popup{
    position: absolute;
    left: 25%;
    right: 25%;
    top: 25%;
    bottom: 25%;
    margin: auto;
    background: white;
    -webkit-animation: popup 0.2s;
            animation: popup 0.2s;
  }
    .inner_popup span{
    display: inline-block;
    background-color: transparent;
    padding-left: 10px;
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
  }
  .tdStyle {
    padding-bottom: 10px;
    padding-top:30px;
    width:200px
  }
  .textTransformation{
    padding-bottom: 10px;
    padding-top:30px;
    width:200px;
    text-transform:capitalize;
  } 
  .orderDetail{
    display: flex;
    align-items: center;
  }
  .productDetail{
    margin-left: 10px;
  }
  .details{
    margin-left: 20px;
  }
  .popup_inner span{
    display: inline-block;
    background-color: #FF6347;
    padding-left: 10px;
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
  }
  @-webkit-keyframes popup {
    0%{
      -webkit-transform: scale(0);
              transform: scale(0)
    }
    10%{
      -webkit-transform: scale(0.1);
              transform: scale(0.1)
    }
    20%{
      -webkit-transform: scale(0.2);
              transform: scale(0.2)
    }
    30%{
      -webkit-transform: scale(0.3);
              transform: scale(0.3)
    }
    40%{
      -webkit-transform: scale(0.4);
              transform: scale(0.4);
    }
    50%{
      -webkit-transform: scale(0.5);
              transform: scale(0.5);
    }
    60%{
      -webkit-transform: scale(0.6);
              transform: scale(0.6);
    }
    70%{
      -webkit-transform: scale(0.7);
              transform: scale(0.7);
    }
    80%{
      -webkit-transform: scale(0.8);
              transform: scale(0.8);
    }
    90%{
      -webkit-transform: scale(0.9);
              transform: scale(0.9);
    }
    100%{
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
  @keyframes popup {
    0%{
      -webkit-transform: scale(0);
              transform: scale(0)
    }
    10%{
      -webkit-transform: scale(0.1);
              transform: scale(0.1)
    }
    20%{
      -webkit-transform: scale(0.2);
              transform: scale(0.2)
    }
    30%{
      -webkit-transform: scale(0.3);
              transform: scale(0.3)
    }
    40%{
      -webkit-transform: scale(0.4);
              transform: scale(0.4);
    }
    50%{
      -webkit-transform: scale(0.5);
              transform: scale(0.5);
    }
    60%{
      -webkit-transform: scale(0.6);
              transform: scale(0.6);
    }
    70%{
      -webkit-transform: scale(0.7);
              transform: scale(0.7);
    }
    80%{
      -webkit-transform: scale(0.8);
              transform: scale(0.8);
    }
    90%{
      -webkit-transform: scale(0.9);
              transform: scale(0.9);
    }
    100%{
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
.PresetDateRangePicker_panel {
    padding: 0 22px 11px
  }
  .PresetDateRangePicker_button {
    position: relative;
    height: 100%;
    text-align: center;
    background: 0 0;
    border: 2px solid #00a699;
    color: #00a699;
    padding: 4px 12px;
    margin-right: 8px;
    font: inherit;
    font-weight: 700;
    line-height: normal;
    overflow: visible;
    box-sizing: border-box;
    cursor: pointer
  }
  .PresetDateRangePicker_button:active {
    outline: 0
  }
  .PresetDateRangePicker_button__selected {
    color: #fff;
    background: #00a699
  }
  .SingleDatePickerInput {
    display: inline-block;
    background-color: #fff
  }
  .SingleDatePickerInput__withBorder {
    border-radius: 2px;
    border: 1px solid #dbdbdb
  }
  .SingleDatePickerInput__rtl {
    direction: rtl
  }
  .SingleDatePickerInput__disabled {
    background-color: #f2f2f2
  }
  .SingleDatePickerInput__block {
    display: block
  }
  .SingleDatePickerInput__showClearDate {
    padding-right: 30px
  }
  .SingleDatePickerInput_clearDate {
    background: 0 0;
    border: 0;
    color: inherit;
    font: inherit;
    line-height: normal;
    overflow: visible;
    cursor: pointer;
    padding: 10px;
    margin: 0 10px 0 5px;
    position: absolute;
    right: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%)
  }
  .SingleDatePickerInput_clearDate__default:focus,
  .SingleDatePickerInput_clearDate__default:hover {
    background: #dbdbdb;
    border-radius: 50%
  }
  .SingleDatePickerInput_clearDate__small {
    padding: 6px
  }
  .SingleDatePickerInput_clearDate__hide {
    visibility: hidden
  }
  .SingleDatePickerInput_clearDate_svg {
    fill: #82888a;
    height: 12px;
    width: 15px;
    vertical-align: middle
  }
  .SingleDatePickerInput_clearDate_svg__small {
    height: 9px
  }
  .SingleDatePickerInput_calendarIcon {
    background: 0 0;
    border: 0;
    color: inherit;
    font: inherit;
    line-height: normal;
    overflow: visible;
    cursor: pointer;
    display: inline-block;
    vertical-align: middle;
    padding: 10px;
    margin: 0 5px 0 10px
  }
  .SingleDatePickerInput_calendarIcon_svg {
    fill: #82888a;
    height: 15px;
    width: 14px;
    vertical-align: middle
  }
  .SingleDatePicker {
    position: relative;
    display: inline-block
  }
  .SingleDatePicker__block {
    display: block
  }
  .SingleDatePicker_picker {
    z-index: 1;
    background-color: #fff;
    position: absolute
  }
  .SingleDatePicker_picker__rtl {
    direction: rtl
  }
  .SingleDatePicker_picker__directionLeft {
    left: 0
  }
  .SingleDatePicker_picker__directionRight {
    right: 0
  }
  .SingleDatePicker_picker__portal {
    background-color: rgba(0,0,0,.3);
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%
  }
  .SingleDatePicker_picker__fullScreenPortal {
    background-color: #fff
  }
  .SingleDatePicker_closeButton {
    background: 0 0;
    border: 0;
    color: inherit;
    font: inherit;
    line-height: normal;
    overflow: visible;
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    padding: 15px;
    z-index: 2
  }
  .SingleDatePicker_closeButton:focus,
  .SingleDatePicker_closeButton:hover {
    color: darken(#cacccd,10%);
    text-decoration: none
  }
  .SingleDatePicker_closeButton_svg {
    height: 15px;
    width: 15px;
    fill: #cacccd
  }
  .DayPickerKeyboardShortcuts_buttonReset {
    background: 0 0;
    border: 0;
    border-radius: 0;
    color: inherit;
    font: inherit;
    line-height: normal;
    overflow: visible;
    padding: 0;
    cursor: pointer;
    font-size: 14px
  }
  .DayPickerKeyboardShortcuts_buttonReset:active {
    outline: 0
  }
  .DayPickerKeyboardShortcuts_show {
    width: 22px;
    position: absolute;
    z-index: 2
  }
  .DayPickerKeyboardShortcuts_show__bottomRight {
    border-top: 26px solid transparent;
    border-right: 33px solid #00a699;
    bottom: 0;
    right: 0
  }
  .DayPickerKeyboardShortcuts_show__bottomRight:hover {
    border-right: 33px solid #008489
  }
  .DayPickerKeyboardShortcuts_show__topRight {
    border-bottom: 26px solid transparent;
    border-right: 33px solid #00a699;
    top: 0;
    right: 0
  }
  .DayPickerKeyboardShortcuts_show__topRight:hover {
    border-right: 33px solid #008489
  }
  .DayPickerKeyboardShortcuts_show__topLeft {
    border-bottom: 26px solid transparent;
    border-left: 33px solid #00a699;
    top: 0;
    left: 0
  }
  .DayPickerKeyboardShortcuts_show__topLeft:hover {
    border-left: 33px solid #008489
  }
  .DayPickerKeyboardShortcuts_showSpan {
    color: #fff;
    position: absolute
  }
  .DayPickerKeyboardShortcuts_showSpan__bottomRight {
    bottom: 0;
    right: -28px
  }
  .DayPickerKeyboardShortcuts_showSpan__topRight {
    top: 1px;
    right: -28px
  }
  .DayPickerKeyboardShortcuts_showSpan__topLeft {
    top: 1px;
    left: -28px
  }
  .DayPickerKeyboardShortcuts_panel {
    overflow: auto;
    background: #fff;
    border: 1px solid #dbdbdb;
    border-radius: 2px;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 2;
    padding: 22px;
    margin: 33px
  }
  .DayPickerKeyboardShortcuts_title {
    font-size: 16px;
    font-weight: 700;
    margin: 0
  }
  .DayPickerKeyboardShortcuts_list {
    list-style: none;
    padding: 0;
    font-size: 14px
  }
  .DayPickerKeyboardShortcuts_close {
    position: absolute;
    right: 22px;
    top: 22px;
    z-index: 2
  }
  .DayPickerKeyboardShortcuts_close:active {
    outline: 0
  }
  .DayPickerKeyboardShortcuts_closeSvg {
    height: 15px;
    width: 15px;
    fill: #cacccd
  }
  .DayPickerKeyboardShortcuts_closeSvg:focus,
  .DayPickerKeyboardShortcuts_closeSvg:hover {
    fill: #82888a
  }
  .CalendarDay {
    box-sizing: border-box;
    cursor: pointer;
    font-size: 14px;
    text-align: center
  }
  .CalendarDay:active {
    outline: 0
  }
  .CalendarDay__defaultCursor {
    cursor: default
  }
  .CalendarDay__default {
    border: 1px solid #e4e7e7;
    color: #484848;
    background: #fff
  }
  .CalendarDay__default:hover {
    background: #e4e7e7;
    border: 1px double #e4e7e7;
    color: inherit
  }
  .CalendarDay__hovered_offset {
    background: #f4f5f5;
    border: 1px double #e4e7e7;
    color: inherit
  }
  .CalendarDay__outside {
    border: 0;
    background: #fff;
    color: #484848
  }
  .CalendarDay__outside:hover {
    border: 0
  }
  .CalendarDay__blocked_minimum_nights {
    background: #fff;
    border: 1px solid #eceeee;
    color: #cacccd
  }
  .CalendarDay__blocked_minimum_nights:active,
  .CalendarDay__blocked_minimum_nights:hover {
    background: #fff;
    color: #cacccd
  }
  .CalendarDay__highlighted_calendar {
    background: #ffe8bc;
    color: #484848
  }
  .CalendarDay__highlighted_calendar:active,
  .CalendarDay__highlighted_calendar:hover {
    background: #ffce71;
    color: #484848
  }
  .CalendarDay__selected_span {
    background: #66e2da;
    border: 1px solid #33dacd;
    color: #fff
  }
  .CalendarDay__selected_span:active,
  .CalendarDay__selected_span:hover {
    background: #33dacd;
    border: 1px solid #33dacd;
    color: #fff
  }
  .CalendarDay__last_in_range {
    border-right: #00a699
  }
  .CalendarDay__selected,
  .CalendarDay__selected:active,
  .CalendarDay__selected:hover {
    background: #00a699;
    border: 1px solid #00a699;
    color: #fff
  }
  .CalendarDay__hovered_span,
  .CalendarDay__hovered_span:hover {
    background: #b2f1ec;
    border: 1px solid #80e8e0;
    color: #007a87
  }
  .CalendarDay__hovered_span:active {
    background: #80e8e0;
    border: 1px solid #80e8e0;
    color: #007a87
  }
  .CalendarDay__blocked_calendar,
  .CalendarDay__blocked_calendar:active,
  .CalendarDay__blocked_calendar:hover {
    background: #cacccd;
    border: 1px solid #cacccd;
    color: #82888a
  }
  .CalendarDay__blocked_out_of_range,
  .CalendarDay__blocked_out_of_range:active,
  .CalendarDay__blocked_out_of_range:hover {
    background: #fff;
    border: 1px solid #e4e7e7;
    color: #cacccd
  }
  .CalendarMonth {
    background: #fff;
    text-align: center;
    vertical-align: top;
    -webkit-user-select: none;
    user-select: none
  }
  .CalendarMonth_table {
    border-collapse: collapse;
    border-spacing: 0
  }
  .CalendarMonth_verticalSpacing {
    border-collapse: separate
  }
  .CalendarMonth_caption {
    color: #484848;
    font-size: 18px;
    text-align: center;
    padding-top: 22px;
    padding-bottom: 37px;
    caption-side: initial
  }
  .CalendarMonth_caption__verticalScrollable {
    padding-top: 12px;
    padding-bottom: 7px
  }
  .CalendarMonthGrid {
    background: #fff;
    text-align: left;
    z-index: 0
  }
  .CalendarMonthGrid__animating {
    z-index: 1
  }
  .CalendarMonthGrid__horizontal {
    position: absolute;
    left: 9px
  }
  .CalendarMonthGrid__vertical {
    margin: 0 auto
  }
  .CalendarMonthGrid__vertical_scrollable {
    margin: 0 auto;
    overflow-y: scroll
  }
  .CalendarMonthGrid_month__horizontal {
    display: inline-block;
    vertical-align: top;
    min-height: 100%
  }
  .CalendarMonthGrid_month__hideForAnimation {
    position: absolute;
    z-index: -1;
    opacity: 0;
    pointer-events: none
  }
  .CalendarMonthGrid_month__hidden {
    visibility: hidden
  }
  .DayPickerNavigation {
    position: relative;
    z-index: 2
  }
  .DayPickerNavigation__horizontal {
    height: 0
  }
  .DayPickerNavigation__verticalDefault {
    position: absolute;
    width: 100%;
    height: 52px;
    bottom: 0;
    left: 0
  }
  .DayPickerNavigation__verticalScrollableDefault {
    position: relative
  }
  .DayPickerNavigation_button {
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    border: 0;
    padding: 0;
    margin: 0
  }
  .DayPickerNavigation_button__default {
    border: 1px solid #e4e7e7;
    background-color: #fff;
    color: #757575
  }
  .DayPickerNavigation_button__default:focus,
  .DayPickerNavigation_button__default:hover {
    border: 1px solid #c4c4c4
  }
  .DayPickerNavigation_button__default:active {
    background: #f2f2f2
  }
  .DayPickerNavigation_button__horizontalDefault {
    position: absolute;
    top: 18px;
    line-height: .78;
    border-radius: 3px;
    padding: 6px 9px
  }
  .DayPickerNavigation_leftButton__horizontalDefault {
    left: 22px
  }
  .DayPickerNavigation_rightButton__horizontalDefault {
    right: 22px
  }
  .DayPickerNavigation_button__verticalDefault {
    padding: 5px;
    background: #fff;
    box-shadow: 0 0 5px 2px rgba(0,0,0,.1);
    position: relative;
    display: inline-block;
    height: 100%;
    width: 50%
  }
  .DayPickerNavigation_nextButton__verticalDefault {
    border-left: 0
  }
  .DayPickerNavigation_nextButton__verticalScrollableDefault {
    width: 100%
  }
  .DayPickerNavigation_svg__horizontal {
    height: 19px;
    width: 19px;
    fill: #82888a;
    display: block
  }
  .DayPickerNavigation_svg__vertical {
    height: 42px;
    width: 42px;
    fill: #484848;
    display: block
  }
  .DayPicker {
    background: #fff;
    position: relative;
    text-align: left
  }
  .DayPicker__horizontal {
    background: #fff
  }
  .DayPicker__verticalScrollable {
    height: 100%
  }
  .DayPicker__hidden {
    visibility: hidden
  }
  .DayPicker__withBorder {
    box-shadow: 0 2px 6px rgba(0,0,0,.05),0 0 0 1px rgba(0,0,0,.07);
    border-radius: 3px
  }
  .DayPicker_portal__horizontal {
    box-shadow: none;
    position: absolute;
    left: 50%;
    top: 50%
  }
  .DayPicker_portal__vertical {
    position: initial
  }
  .DayPicker_focusRegion {
    outline: 0
  }
  .DayPicker_calendarInfo__horizontal,
  .DayPicker_wrapper__horizontal {
    display: inline-block;
    vertical-align: top
  }
  .DayPicker_weekHeaders {
    position: relative
  }
  .DayPicker_weekHeaders__horizontal {
    margin-left: 9px
  }
  .DayPicker_weekHeader {
    color: #757575;
    position: absolute;
    top: 62px;
    z-index: 2;
    text-align: left
  }
  .DayPicker_weekHeader__vertical {
    left: 50%
  }
  .DayPicker_weekHeader__verticalScrollable {
    top: 0;
    display: table-row;
    border-bottom: 1px solid #dbdbdb;
    background: #fff;
    margin-left: 0;
    left: 0;
    width: 100%;
    text-align: center
  }
  .DayPicker_weekHeader_ul {
    list-style: none;
    margin: 1px 0;
    padding-left: 0;
    padding-right: 0;
    font-size: 14px
  }
  .DayPicker_weekHeader_li {
    display: inline-block;
    text-align: center
  }
  .DayPicker_transitionContainer {
    position: relative;
    overflow: hidden;
    border-radius: 3px
  }
  .DayPicker_transitionContainer__horizontal {
    transition: height .2s ease-in-out
  }
  .DayPicker_transitionContainer__vertical {
    width: 100%
  }
  .DayPicker_transitionContainer__verticalScrollable {
    padding-top: 20px;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    overflow-y: scroll
  }
  .DateInput {
    margin: 0;
    padding: 0;
    background: #fff;
    position: relative;
    display: inline-block;
    width: 130px;
    vertical-align: middle
  }
  .DateInput__small {
    width: 97px
  }
  .DateInput__block {
    width: 100%
  }
  .DateInput__disabled {
    background: #f2f2f2;
    color: #dbdbdb
  }
  .DateInput_input {
    font-weight: 200;
    font-size: 19px;
    line-height: 24px;
    color: #484848;
    background-color: #fff;
    width: 100%;
    padding: 11px 11px 9px;
    border: 0;
    border-top: 0;
    border-right: 0;
    border-bottom: 2px solid transparent;
    border-left: 0;
    border-radius: 0
  }
  .DateInput_input__small {
    font-size: 15px;
    line-height: 18px;
    letter-spacing: .2px;
    padding: 7px 7px 5px
  }
  .DateInput_input__regular {
    font-weight: auto
  }
  .DateInput_input__readOnly {
    -webkit-user-select: none;
    user-select: none
  }
  .DateInput_input__focused {
    outline: 0;
    background: #fff;
    border: 0;
    border-top: 0;
    border-right: 0;
    border-bottom: 2px solid #008489;
    border-left: 0
  }
  .DateInput_input__disabled {
    background: #f2f2f2;
    font-style: italic
  }
  .DateInput_screenReaderMessage {
    border: 0;
    clip: rect(0,0,0,0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px
  }
  .DateInput_fang {
    position: absolute;
    width: 20px;
    height: 10px;
    left: 22px;
    z-index: 2
  }
  .DateInput_fangShape {
    fill: #fff
  }
  .DateInput_fangStroke {
    stroke: #dbdbdb;
    fill: transparent
  }
  .DateRangePickerInput {
    background-color: #fff;
    display: inline-block
  }
  .DateRangePickerInput__disabled {
    background: #f2f2f2
  }
  .DateRangePickerInput__withBorder {
    border-radius: 2px;
    border: 1px solid #dbdbdb
  }
  .DateRangePickerInput__rtl {
    direction: rtl
  }
  .DateRangePickerInput__block {
    display: block
  }
  .DateRangePickerInput__showClearDates {
    padding-right: 30px
  }
  .DateRangePickerInput_arrow {
    display: inline-block;
    vertical-align: middle;
    color: #484848
  }
  .DateRangePickerInput_arrow_svg {
    vertical-align: middle;
    fill: #484848;
    height: 24px;
    width: 24px
  }
  .DateRangePickerInput_clearDates {
    background: 0 0;
    border: 0;
    color: inherit;
    font: inherit;
    line-height: normal;
    overflow: visible;
    cursor: pointer;
    padding: 10px;
    margin: 0 10px 0 5px;
    position: absolute;
    right: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%)
  }
  .DateRangePickerInput_clearDates__small {
    padding: 6px
  }
  .DateRangePickerInput_clearDates_default:focus,
  .DateRangePickerInput_clearDates_default:hover {
    background: #dbdbdb;
    border-radius: 50%
  }
  .DateRangePickerInput_clearDates__hide {
    visibility: hidden
  }
  .DateRangePickerInput_clearDates_svg {
    fill: #82888a;
    height: 12px;
    width: 15px;
    vertical-align: middle
  }
  .DateRangePickerInput_clearDates_svg__small {
    height: 9px
  }
  .DateRangePickerInput_calendarIcon {
    background: 0 0;
    border: 0;
    color: inherit;
    font: inherit;
    line-height: normal;
    overflow: visible;
    cursor: pointer;
    display: inline-block;
    vertical-align: middle;
    padding: 10px;
    margin: 0 5px 0 10px
  }
  .DateRangePickerInput_calendarIcon_svg {
    fill: #82888a;
    height: 15px;
    width: 14px;
    vertical-align: middle
  }
  .DateRangePicker {
    position: relative;
    display: inline-block
  }
  .DateRangePicker__block {
    display: block
  }
  .DateRangePicker_picker {
    z-index: 1;
    background-color: #fff;
    position: absolute
  }
  .DateRangePicker_picker__rtl {
    direction: rtl
  }
  .DateRangePicker_picker__directionLeft {
    left: 0
  }
  .DateRangePicker_picker__directionRight {
    right: 0
  }
  .DateRangePicker_picker__portal {
    background-color: rgba(0,0,0,.3);
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%
  }
  .DateRangePicker_picker__fullScreenPortal {
    background-color: #fff
  }
  .DateRangePicker_closeButton {
    background: 0 0;
    border: 0;
    color: inherit;
    font: inherit;
    line-height: normal;
    overflow: visible;
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    padding: 15px;
    z-index: 2
  }
  .DateRangePicker_closeButton:focus,
  .DateRangePicker_closeButton:hover {
    color: darken(#cacccd,10%);
    text-decoration: none
  }
  .DateRangePicker_closeButton_svg {
    height: 15px;
    width: 15px;
    fill: #cacccd
  }
#snackbar {
    visibility: hidden; /* Hidden by default. Visible on click */
    min-width: 250px; /* Set a default minimum width */
    margin-left: -125px; /* Divide value of min-width by 2 */
    background-color: #333; /* Black background color */
    color: #fff; /* White text color */
    text-align: center; /* Centered text */
    border-radius: 2px; /* Rounded borders */
    padding: 16px; /* Padding */
    position: fixed; /* Sit on top of the screen */
    z-index: 1; /* Add a z-index if needed */
    right: 5%; /* Center the snackbar */
    bottom: 30px; /* 30px from the bottom */
}

/* Show the snackbar when clicking on a button (class added with JavaScript) */
#snackbar.show {
    visibility: visible; /* Show the snackbar */

/* Add animation: Take 0.5 seconds to fade in and out the snackbar. 
However, delay the fade out process for 2.5 seconds */
    -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
    animation: fadein 0.5s, fadeout 0.5s 2.5s;
}

/* Animations to fade the snackbar in and out */
@-webkit-keyframes fadein {
    from {bottom: 0; opacity: 0;} 
    to {bottom: 30px; opacity: 1;}
}

@keyframes fadein {
    from {bottom: 0; opacity: 0;}
    to {bottom: 30px; opacity: 1;}
}

@-webkit-keyframes fadeout {
    from {bottom: 30px; opacity: 1;} 
    to {bottom: 0; opacity: 0;}
}

@keyframes fadeout {
    from {bottom: 30px; opacity: 1;}
    to {bottom: 0; opacity: 0;}
}

.dropDownItems {
    padding: 0;
    width: 250px;
    position: absolute;
    z-index: 10;
    top: 50px;
    left: 15px;
    list-style-type: none;
    display: none;
    background-color: rgb(237, 237, 237);
    transition: opacity .35s ease-in-out;
    -moz-transition: opacity .35s ease-in-out;
    -webkit-transition: opacity .35s ease-in-out;
}
.dropDownItems li {
    padding:0px 10px;
}
.dropDownItems li:hover {
    background-color:rgb(170, 169, 169);
}
.dropDownItems a {
    display: block;
    word-wrap: break-word;
    color: black;
    text-decoration: none;
    padding:5px 0;
}
.dropDownItems a:hover, .dropDownItems a:active, .dropDownItems a:focus {
    color: black;
    text-decoration: none
}
.businessDropDown:hover .dropDownItems {
    display: block;
    transition: opacity .35s ease-in-out;
    -moz-transition: opacity .35s ease-in-out;
    -webkit-transition: opacity .35s ease-in-out;

}

.notificationCount {
    position: relative;
}
.count{
    display: inline-block;
    width: 16px;
    height: 16px;
    border: 1px solid grey;
    border-radius: 50%;
    position: absolute;
    left: 5px;
    top: 2px;
    background-color: white;
    text-align: center;
    font-size: 10px;
    color: black;
}
