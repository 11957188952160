.submitStyle{
    background-color: #595D65;
    background-image: none;
    width: 85%;
    margin-bottom: 32px;
    margin-top:15px;
    font-weight: normal;
    outline: none;
    color: #FFFFFF;
}
.btn.submitStyle:hover{
    outline: none;
    color: #FFFFFF;
}
.hover:hover label{
    display: block !important;
    color: #FFFFFF;
}
.slider-control-bottomcenter ul{
    top: 28px !important;
}
.slider-control-bottomcenter ul li button{
    padding: 0 !important;
}
.header-main{
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 2;
}
.loader{
    background-color: rgb(241, 244, 245);
    position: fixed; 
    z-index: 999; 
    height: 100%; width: 100%; overflow: visible; margin: auto; top: 0; left: 0; bottom: 0; right: 0; text-align: center;
}
.new-orders{
    display: flex;
    justify-content: space-between;
}
.content{
    display: flex;
    width: 45%;
    margin-top: 30px;
}
.content img{
    width: 88px;
    height: 88px;
}
.content:nth-child(even){
    align-self: flex-end;
}
.content:nth-child(odd){
    align-self: flex-start;
}
.orders-list{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}
.item-details{
    margin-left: 20px;
    color: #595D65;
}
.item-name{
    margin-bottom: 5px;
}
.availablility{
    margin-top: 5px;
}
.availablility span{
    display: inline-block;
    width: 120px;
    margin-right: 5px;
    border: 1px solid #D8D8D8;
    border-radius: 4px;
    text-align: center;
    font-size: 12px;
    padding: 5px 0 3px 0;
    cursor: pointer;
}
.order-num{
    font-size: 12px;
}
.overview{
    margin-top: 100px;
}
.stats{
    border: 1px solid #979797;
    border-radius: 4px;
    margin-top: 20px;
}
.stats div{
    display: inline-block;
    width: 19.8%;
    border-right:1px solid #979797; 
    padding: 20px 0;
    text-align: center;
    color: #595D65;
}
.stats div:last-child{
    border: 0;
}
.number{
    font-size: 28px;
}
.name{
    font-weight: 500;
}
.view-all a,.view-all a:hover,.view-all a:focus{
    color: #595D65;
    text-decoration: none;
}
.selling-price{
    position: relative;
}
.currency{
    position: absolute;
    top: 28px;
    left: 24%;
}
.selling-price input{
    padding-left: 15px !important;
}
.date-picker{
    margin-left: -15px;
    margin-right: -15px;
}
.date-picker .SingleDatePickerInput__withBorder{
    width: 100%;
    border: none;
    border-bottom: 1px solid #C7D1DB;
}
.date-picker .SingleDatePicker{
    width: 100%;
}
.date-picker .SingleDatePickerInput_calendarIcon{
    margin-left: 0;
    padding-left: 0;
}
.date-picker .DateInput{
    width: 85%;
}