.tim2_cls{
    width: 386px;
    border: 1px solid #ccc;
    /* height: 19px; */
    border-right: none;
    border-left: none;
    border-top: none;
    outline: none !important;
    font-size: 15px;
    color:rgb(138, 141, 144);
    margin-bottom: 20px;
}
.sync_main{
    display: flex;
    flex-direction: row;
}
.sync_sub1{
     display: flex;
     flex-direction: column;
     margin-left: 27% !important;
     font-size: 12px;
     font-weight: 400;
}
.sync_sub2{
    display: flex;
    flex-direction: column;
    margin-left: 8% !important;
    font-size: 12px;
    font-weight: 400;
}
.sync_sub2 #success{
    color: #043505;
    font-weight: 600
}
.sync_sub2 #fail{
    color: red;
    font-weight: 600
}
.sync_sub1 .spa1{
    font-size: 15px;
    color: rgb(138, 141, 144);
    margin: auto;
    margin-top: 7px;
}
.sync_sub2 .spa1{
    font-size: 15px;
    color: rgb(138, 141, 144);
    margin: auto;
    margin-top: 7px;
}

@media(min-width: 1px) and (max-width: 320.98px)
{
.tim_div_input{
    margin-left: -6% !important;
}
.tim2_cls {
    width: 164px !important;
}
.sync_sub1{
    margin-left: 0px !important;
}
.sync_sub2{
    margin-left: 15% !important;
}
}
@media(min-width: 320px) and (max-width: 375.98px){
    .tim2_cls {
        width: 162px !important;
    }
    .tim_div_input{
    margin-left: -5% !important;
    }
    .sync_sub1{
        margin-left: 0px !important;
    }
    .sync_sub2{
        margin-left: 16% !important;
    }
}
@media (min-width: 375px) and (max-width: 425.98px) {
    .tim2_cls {
        width: 197px !important;
    }
    .tim_div_input{
    margin-left: -5% !important;
    }
    .sync_sub1{
        margin-left: 0px !important;
    }
}
@media (min-width: 425px) and (max-width: 576.98px){
    .tim2_cls {
        width: 229px !important;
    }
    .tim_div_input{
    margin-left: -5% !important;
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {
    .tim2_cls {
        width: 323px !important;
    }
    .tim_div_input{
    margin-left: -5% !important;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    .tim2_cls {
        width: 237px !important;
    }
    .tim_div_input{
    margin-left: 24% !important;
    }
    .catsubcat{
        margin-left: 22% !important;
        font-size:  10px;
        font-weight: 400;
        }
}

@media (min-width: 992px) and (max-width: 1199.98px)
{
    .tim2_cls {
        width: 315px !important;
    }
    .tim_div_input{
    margin-left: 24% !important;
    }
    .catsubcat{
        margin-left: 20% !important;
        font-size:  10px;
        font-weight: 400;
        }
        .sync_sub1{
            margin-left: 23% !important;
        }
}
@media (min-width: 1200px) and (max-width: 1349.98px) {
    .tim2_cls {
        width: 408px !important;
    }
    .tim_div_input{
        margin-left: 24% !important;
        }
        .sync_sub1{
            margin-left: 23% !important;
        }
}
@media (min-width: 1428px) and (max-width: 1350px){
.tim_div_input{
    margin-left: 24%;
}
}
@media (min-width: 1440px) and (max-width: 1999.98px){
    .tim2_cls {
        width: 440px !important;
    }
    .tim_div_input{
        margin-left: 24% !important;
        }
        .catsubcat{
            margin-left: 19% !important;
            font-size:  10px;
            font-weight: 400;
            }
            .sync_sub1{
                margin-left: 24% !important;
            }
}
@media (min-width: 1350px) and (max-width: 1439.98px) {
    .tim_div_input{
        margin-left: 24% !important;
        }
        .catsubcat{
            margin-left: 20% !important;
            font-size:  10px;
            font-weight: 400;
            }
}
@media (min-width: 2000px) and (max-width: 2499.98px){
    .tim2_cls {
        width: 611px !important;
    }
    .tim_div_input{
        margin-left: 24% !important;
        }
        .catsubcat{
            margin-left: 20% !important;
            font-size:  10px;
            font-weight: 400;
            }
}

@media (min-width: 2500px) and (max-width: 2999.98px){
    .tim2_cls {
        width: 779px !important;
    }
    .tim_div_input{
        margin-left: 24% !important;
        }
        .catsubcat{
            margin-left: 18% !important;
            font-size:  10px;
            font-weight: 400;
            }
    .sync_sub1{
        margin-left: 31% !important;
    }
}
