#snackbar {
    visibility: hidden; /* Hidden by default. Visible on click */
    min-width: 250px; /* Set a default minimum width */
    margin-left: -125px; /* Divide value of min-width by 2 */
    background-color: #333; /* Black background color */
    color: #fff; /* White text color */
    text-align: center; /* Centered text */
    border-radius: 2px; /* Rounded borders */
    padding: 16px; /* Padding */
    position: fixed; /* Sit on top of the screen */
    z-index: 1; /* Add a z-index if needed */
    right: 5%; /* Center the snackbar */
    bottom: 30px; /* 30px from the bottom */
}

/* Show the snackbar when clicking on a button (class added with JavaScript) */
#snackbar.show {
    visibility: visible; /* Show the snackbar */

/* Add animation: Take 0.5 seconds to fade in and out the snackbar. 
However, delay the fade out process for 2.5 seconds */
    -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
    animation: fadein 0.5s, fadeout 0.5s 2.5s;
}

/* Animations to fade the snackbar in and out */
@-webkit-keyframes fadein {
    from {bottom: 0; opacity: 0;} 
    to {bottom: 30px; opacity: 1;}
}

@keyframes fadein {
    from {bottom: 0; opacity: 0;}
    to {bottom: 30px; opacity: 1;}
}

@-webkit-keyframes fadeout {
    from {bottom: 30px; opacity: 1;} 
    to {bottom: 0; opacity: 0;}
}

@keyframes fadeout {
    from {bottom: 30px; opacity: 1;}
    to {bottom: 0; opacity: 0;}
}

.dropDownItems {
    padding: 0;
    width: 250px;
    position: absolute;
    z-index: 10;
    top: 50px;
    left: 15px;
    list-style-type: none;
    display: none;
    background-color: rgb(237, 237, 237);
    transition: opacity .35s ease-in-out;
    -moz-transition: opacity .35s ease-in-out;
    -webkit-transition: opacity .35s ease-in-out;
}
.dropDownItems li {
    padding:0px 10px;
}
.dropDownItems li:hover {
    background-color:rgb(170, 169, 169);
}
.dropDownItems a {
    display: block;
    word-wrap: break-word;
    color: black;
    text-decoration: none;
    padding:5px 0;
}
.dropDownItems a:hover, .dropDownItems a:active, .dropDownItems a:focus {
    color: black;
    text-decoration: none
}
.businessDropDown:hover .dropDownItems {
    display: block;
    transition: opacity .35s ease-in-out;
    -moz-transition: opacity .35s ease-in-out;
    -webkit-transition: opacity .35s ease-in-out;

}

.notificationCount {
    position: relative;
}
.count{
    display: inline-block;
    width: 16px;
    height: 16px;
    border: 1px solid grey;
    border-radius: 50%;
    position: absolute;
    left: 5px;
    top: 2px;
    background-color: white;
    text-align: center;
    font-size: 10px;
    color: black;
}