.css-1aya2g8{
    border-width: 0 !important;
    border-bottom-width: 1px !important;
    border-radius: 0 !important;
    background-color: transparent !important;
    
}
.multi-dropdown .css-1uq0kb5{
    display: none;
}
.form-group .css-10nd86i{
    padding: 0;
    padding: 0 10px;
    background-image: url("../../images/dropdown.png");
    background-repeat: no-repeat;
    background-position: 95% 49%;
}
.css-10nd86i .css-vj8t7z{
    border-width: 0;
    border-bottom-width: 1px;
    border-radius: 0;
    background-color: transparent;
}
.form-group .css-2o5izw{
    border-width: 0;
    border-bottom-width: 1px;
    border-bottom-color: rgb(199, 209, 219);
    border-radius: 0;
    box-shadow: none;
    background-image: url("../../images/dropdown.png");
    background-repeat: no-repeat;
    background-position: 97% 50%;
}
.form-group .css-2o5izw:hover{
    border-width: 0;
    border-bottom-width: 1px ;
    border-radius: 0;
    border-color:rgb(199, 209, 219);
}
.form-group .css-1rtrksz{
    padding: 0;
}
.form-group .css-d8oujb,.form-group .css-1sjym57,.form-group .css-1ep9fjw{
    display: none;
}
.form-group .css-2o5izw{
    transition: all 0s !important;
}

.header-main .navbar-brand img{
    display: inline-block;
}

.time_cls{
    width: 253px;
    margin: auto;
    margin-left: 91px;
}

.shopifycls{
    width: auto;
    margin-left: 15px;
}
.shopify_yon{
    margin-bottom: 15px;
}
.tim1_cls{
    width: 386px;
    border: 1px solid #ccc;
    /* height: 19px; */
    border-right: none;
    border-left: none;
    border-top: none;
    outline: none !important;
    font-size: 15px;
    color:rgb(138, 141, 144);
}





@media screen and (max-width:767px){
    .main-container{
        margin-top: 40px !important;
    }
    textarea{
        width: 100%;
    }
    .register-heading{
        font-size: 18px !important;
    }
    .register-main .form-group{
        margin-left: 0 !important;
    }
    .form-group .css-10nd86i{
        padding: 0;
        background-position: 98% 49%;
    }
    .register-main{
        width: 100% !important;
        border-width: 0 !important;
    }
    .register-main .form-horizontal .form-group{
        margin-left: 0;
        margin-right: 0;
        margin-top: -9px;
    }
    .header-main .navbar-brand{
            margin-left: 0 !important;
    }
    .navbar-default .navbar-nav li .active{
        border-bottom-width: 0 !important;
    }
    .lbl-mobile{
        width: 0 !important;
    }
    input[type=checkbox].chkbox{
        margin: 2px 0 0;
    }
    .chkbox-container{
        display: flex;
    }
    .verify{
        width: 100% !important;
        text-align: center;
    }
    .submitStyle{
        width: 100% !important;
    }
    .navbar-toggle{
        display: none !important;
    }
    
}


@media screen and (min-device-width: 767px) and (max-device-width:  960px)
{
.timepick-contaioner {
    display: flex;
    margin-left: -139px !important; 
}
}
.shopify_yon{
    margin-left: 24% !important;
    margin-top: 20px;
}
.timepick-contaioner{
    margin-left: 24% !important;
    margin-bottom: 20px;
    margin-top: -20px;
}
.shopifycls{
    margin: auto;

}
.shopify_options{
    display: flex;
}
@media screen and (min-device-width: 1540px) and (max-device-width:  1920px)
{
.timepick-contaioner {
    margin-left: 24% !important;
    margin-bottom: 35px;
    margin-top: -20px;
}
}
@media screen and (min-device-width: 320px) and (max-device-width:  375.98px)
{
    .lab2_cls{
        margin-left: -1px;
    }
    .lbl_cls_tim2{
        margin-right: 22px;
    }
    .labclsreg{
        margin-left: 0% !important;
    }
    .countrycls{
        padding: 0% !important;
    }
    .shopify_yon{
        margin-left: 0% !important;
        margin-bottom: 6% !important;
    }
}


@media screen and (min-device-width: 768px) and (max-device-width:  1024px)
{
.timepick-contaioner {
    margin-left: 24% !important;
}
.timepick-contaioner1{
    margin-left: 14px !important;
}
.tim1_cls {
    width: 260px !important;
    color:rgb(138, 141, 144);
}
.catsubcat{
    margin-left: 18% !important;
    font-size: 15;
    font-weight: 450;
    opacity: 0.5;
}
}


@media screen and (min-device-width: 1025px) and (max-device-width:  1440px)
{
.timepick-contaioner {
    margin-left: 24% !important;
}
.tim1_cls {
    width: 333px;
    color:rgb(138, 141, 144);
}
}
@media screen and (max-width: 425px) and (max-device-width:  370px){
    .labclsreg{
        margin-left: 0% !important;
    }
    .timepick-contaioner {
    margin: auto;
    margin-left: -3px !important;
    margin-bottom: 15px;
    }
    .timepick-contaioner1{
        margin-left: -3px !important;
    }
    .lbl_cls_tim2{
        margin-right: 32px;
    }
    .countrycls{
        padding: 0% !important;
    }
    .shopify_yon{
        margin-left: 0% !important;
        margin-bottom: 6% !important;
    }
}

 
@media (min-width: 375px) and (max-width: 425.98px){
.lbl-mobile {
margin-left: 7px !important;
}
.tim1_cls{
    margin-top: 28px;
}
.timepick-contaioner{
    margin-left: 3px !important;
}
.timepick-contaioner1{
    margin-left: -7px;
    margin-right: 2px;
}
.lab2_cls{
    margin-top: 22px;
}
.catsubcat{
    margin-left: 4% !important;
    font-size: 15;
    font-weight: 450;
    opacity: 0.5;
}
.labclsreg{
    margin-left: 0% !important;
}
.countrycls{
    padding: 0% !important;
}
.shopify_yon{
    margin-left: 0% !important;
    margin-bottom: 6% !important;
}
}
@media (min-width: 1440px) and (max-width: 1999.98px){
    .lbl-mobile{
        width: 24% !important;
    }
    .catsubcat{
        margin-left: 19% !important;
        font-size: 15;
        font-weight: 450;
        opacity: 0.5;
    }
}

@media (min-width: 2000px) and (max-width: 2499.98px){
    .lbl-mobile{
        width: 24% !important;
    }
    .tim1_cls {
width: 468px !important;
    }
    .catsubcat{
        margin-left: 19% !important;
        font-size: 15;
        font-weight: 450;
        opacity: 0.5;
    }
}

@media (min-width: 2500px) and (max-width: 2999.98px){
    .lbl-mobile{
        width: 24% !important;
    }
    .tim1_cls {
        width: 613px !important;
        color:rgb(138, 141, 144);
    }
    .timepick-contaioner{
        margin-left: 24% !important;
    }
    .catsubcat{
        margin-left: 18% !important;
        font-size: 15;
        font-weight: 450;
        opacity: 0.5;
    }
}

